/* Existing CSS for the sidebar and icons */

.sidebar {
  width: 5rem; /* Set initial width for closed sidebar */
  /* background-color: white; */
  border-width: 0px, 2px, 2px, 0px;

border-style: solid;

border-image-source: linear-gradient(107.77deg, #FFD600 8.57%, rgba(0, 0, 0, 0.25) 23.6%, rgba(0, 0, 0, 0.25) 65.5%, #BF7901 94.73%);


  background: linear-gradient(99.99deg, rgba(241, 210, 64, 0.45) 10.36%, rgba(204, 204, 204, 0.03) 94.72%);
  transition: width 0.5s ease;
  overflow-x: hidden;
  overflow-y: hidden;
  height: auto;
  /* box-shadow: 10px 0px 0px 0px rgb(117, 117, 117); */
}

.sidebar.close {
  /* height: 100vh; */
  width: 3rem; /* Set initial width for closed sidebar */
  /* background-color: white; */
  background-color: rgb(12, 12, 12);
  transition: width 0.5s ease;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: 4px 8px 10px 4px rgb(34, 34, 34);
  /* height: 99vh; */
  /* height: auto !important; */
}

.sidebar.open {
  height: 100%;
  width: 10rem; /* Set expanded width for open sidebar */
  /* background-color: white; */
  background-color: transparent;
  transition: width 0.5s ease;
  overflow-x: hidden;
  overflow-y: hidden;
  box-shadow: 2px 4px 10px 2px rgba(233, 183, 18, 0.788);
}

.sidebar-toggle {
  background: linear-gradient(180deg, #CF8A08 -161.54%, #BF7901 0%, #FFEC53 92.31%, #DBAB1C 222.12%);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(233, 183, 18, 0.788);
}

.sidebar-toggle:hover {
  background-color: white;
  color: rgb(243, 181, 98);
}

.sidebar-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.sidebar-menu-item {
  color: rgb(12, 12, 12);
  margin-bottom: 10px;
  cursor: pointer;
  text-align: left; /* Align text to the left */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center items vertically */
  /* padding: ; */
}

.sidebar-icons {
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-icon {
  margin-right: 0.25rem; 
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 20px;
  color: #CF8A08;
  position: relative;
}


/* Tooltip container */
.tooltip {
  display: none;
  position: absolute;
  bottom: 100%; /* Position above the icon */
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  background: linear-gradient(180deg, #CF8A08 -161.54%, #BF7901 0%, #FFEC53 92.31%, #DBAB1C 222.12%);
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  font-size: 0.8rem;
  white-space: nowrap;
  z-index: 1;
}

.sidebar-icon:hover .tooltip {
  display: block;
}

.sidebar-icon:hover {
  color: rgb(238, 238, 238);
}

.sidebar-icon:hover .tooltip::after {
  content: attr(data-tooltip);
}

/* Add margin between icons */
.sidebar-icons li {
  margin-bottom: 10px;
}
