.current-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  /* background-color:rgb(12, 12, 12); */
}

.custom-file-upload-content {
  display: inline-block;
  padding: 0.5rem 0.2rem;
  cursor: pointer;
  background: linear-gradient(106.59deg, #CF8A08 9.3%, #BF7901 50.48%, #FFEC53 74.01%, #DBAB1C 107.1%);
  border-radius: 4px;
  font-size: 14px;
  color: rgb(12, 12, 12);
  text-align: center;
  margin-bottom: 16px;
  width: 100%;
}

.custom-file-upload-content:hover {
  background-color: rgb(12, 12, 12) !important;
  color: white;
  border: 1px solid #f3b562;
}

.file-name {
  margin-top: 8px;
  font-size: 14px;
  color: rgb(12, 12, 12);
  text-align: center;
}