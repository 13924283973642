.footer {
  border-image-source: linear-gradient(107.77deg, #FFD600 8.57%, rgba(0, 0, 0, 0.25) 23.6%, rgba(0, 0, 0, 0.25) 65.5%, #BF7901 94.73%);
border-image-slice: 10%;
background: linear-gradient(99.99deg, rgba(241, 210, 64, 0.45) 10.36%, rgba(204, 204, 204, 0.03) 94.72%);
  box-shadow: 0px -1px 15px 0px #00000075;
  padding: 10px 20px;
  text-align: center;
  width: 100%;
  /* overflow: hidden; */
  height: auto;
  position: fixed;
  bottom: 0;
  text-wrap: wrap;
}

.footer-title {
  color: #FFFFFF;
  margin: 0;
}
