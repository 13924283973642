@media (max-width: 768px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 8px !important;
    }
  
    .ant-table {
      font-size: 12px;
    }
  
    .ant-card-body {
      padding: 12px !important;
    }
  
    .ant-btn {
      font-size: 12px;
    }
  }