.signup-form {
  max-width: 300px;
  margin: 0 auto;
  margin-top: -1rem;
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  color: #FFFFFF;
  /* background-color: rgb(243, 181, 98); */
}

.signup-form h1 {
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 0.25rem;
  font-weight: 900;
  margin-top: -0.25rem;
}

.signup-form h4 {
margin-top: -0.01rem;
  margin-bottom: 3rem;
}

.form-group {
  /* margin-bottom: 15px; */
}

label {
  color: rgb(12, 12, 12);
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  margin-left: -1rem;
border-radius: 8px;
padding-top: 15px;
padding-right: 16px;
padding-bottom: 15px;
padding-left: 16px;
gap: 10px;

}
/*  */

/* button:hover {
  background-color: #0056b3;
} */
.main-container-ls {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 89.75vh;
  width: 100%;
  /* border: 200px solid red; */
}

/* .space {
  margin-top: 20rem;
} */

.login-signup-container {
  width: 25%;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: rgb(243, 181, 98);
  text-align: center;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); Add shadow for better visual separation */
  box-shadow: 0 5px 10px rgba(223, 211, 211, 0.781); /* Add shadow for better visual separation */
}
