.main-container-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 89.75vh;
  width: 100%;
  /* border: 200px solid red; */
}

.login-signup-container {
  width: 30%;
  max-width: 600px;
  margin-top: 1.5rem;
  /* margin: 50px auto; */
  padding: 20px;
  border: 1px solid #ccc;
  /* border-radius: ; */
  border: 2px solid;
border-image-source: linear-gradient(107.77deg, #FFD600 8.57%, rgba(0, 0, 0, 0.25) 23.6%, rgba(0, 0, 0, 0.25) 65.5%, #BF7901 94.73%);
  background: linear-gradient(99.99deg, rgba(241, 210, 64, 0.55) 10.36%, rgba(0, 0, 0) 94.72%);
  /* border-image-source: linear-gradient(107.77deg, #FFD600 8.57%, rgba(0, 0, 0, 0.25) 60.6%, rgba(0, 0, 0, 0.25) 65.5%, #BF7901 94.73%); */
  text-align: left;
  box-shadow: 0 2.5px 5px #BF7901; /* Add shadow for better visual separation */
}

.form-toggle {
  margin-top: 20px;
}

button {
  
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  /* background-color: #F1D24073; */
  background: linear-gradient(180deg, #CF8A08 -161.54%, #BF7901 0%, #FFEC53 92.31%, #DBAB1C 222.12%);
  color: #000000;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 1s ease; /* Smooth transition on hover */
}

button:hover {
  transition: background-color 1s ease;
  background: linear-gradient(180deg, #000000 -161.54%, #000000 0%, #272727 92.31%, #505050 222.12%);
  color: #BF7901;
}

p {
  margin-top: 10px;
  font-size: 14px;
}

p > a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  margin-left: 5px;
}

p > a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1050px) {
  .login-signup-container {
    width: 60%;
    max-width: 600px;
    min-height: 350px;
  }
}

/* @media screen and (max-width: 1000px), (min-width: 501px) {
  .login-signup-container {
    width: 35%;
    max-width: 600px;
    min-height: 350px;
  }
} */
