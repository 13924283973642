@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.testimonial-section{
    padding-bottom: 80px;
}

.testimonial-section-head{
    font-family: 'Poppins';
    font-weight: 700;
    text-align: center;
    font-size: 32px;
    margin-bottom: 10px;
}

.testimonial-section-text{
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 45px;
}

.testimonial-section-text span{
    background: linear-gradient(180deg, #CF8A08 100%, #BF7901 100%,#FFEC53 100%, #DBAB1C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.testimonial-section-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}
.testimonial-section-card-items{
    width: 376px;
    height: 220px;
    border: 2px solid #CF8A08;
    display: block;
    align-content: center;
    border-radius: 12px;
    margin: 10px;
}

.testimonial-card-head{
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 20px;
}
.testimonial-card-head .testimonial-card-image{
    display: flex;
    align-items: center;
    border-radius: 12px;
}
.testimonial-card-head .testimonial-card-image img{
    width: 86px;
    height: 91px;
}

.testimonial-card-text h6{
    font-size: 20px;
    font-family: "Poppins";
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}
.testimonial-card-text img {

    height: 15px;
}
.testimonial-card-body-text{
    margin: 0;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    padding:  0px 20px 20px 20px;
}

@media (max-width:426px){
    .testimonial-section-card-items{
        width: 290px;
        height: 220px;
        border: 2px solid #CF8A08;
        display: block;
        align-content: center;
        border-radius: 12px;
        margin: 10px;
    }
    .testimonial-section-head{
        font-size:20px;
    }
    .testimonial-section-text{
        font-size: 13px;
        padding: 0px 5px ;
    }
    .testimonial-section{
        padding-bottom: 20px;
    }
}
