/* Login.css */

.login-form {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;

  /* background-color: #f9f9f9; */
}

.login-form h1 {
  margin-top: -1rem;
  text-align: left;
  color: rgb(12, 12, 12);
  margin-bottom: -1rem;
  font-weight: 900;
  
}

.login-form h4 {
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 5rem;
}


.form-group {
  margin-bottom: 15px;
}

label {
  color: rgb(12, 12, 12);
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Login.css */

input::placeholder {
  font-weight: 500;
  color: black; /* Change the placeholder color to black */
  opacity: 1; /* Ensures the color is fully opaque */
}


input[type="text"],
input[type="password"] {
  margin-left: -1rem;
  width: 100%;
  padding: 8px;
  /* font-size: 16px; */
  /* border: 1px solid #ccc; */
  border-radius: 0px;
}

input[type="password"] {
  margin-bottom: 2rem;
}

button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  /* background-color: rgb(243, 181, 98); */
  /* color: white; */
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

/* button:hover {
  background-color: #0056b3;
} */
.main-container-ls {
  background-image: url('../image/login-signup-bg.jpg');
  background-size: cover;
  background-position: center;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 89.75vh;
  width: 100%;
  /* border: 200px solid red; */
}

.login-signup-container {
  width: 25%;
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* background-color: rgb(243, 181, 98); */
  text-align: left;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); Add shadow for better visual separation */
  /* box-shadow: 0 5px 10px rgba(223, 211, 211, 0.781); Add shadow for better visual separation */
}
