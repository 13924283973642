@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.main-landing-page {
    padding-top: 50px;
  }
  
  .landing-page-hero {
    position: relative; /* Position relative for absolute positioning of text */
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
  }
  
  .landing-page-hero-text {
    position: relative; /* Position relative to allow stacking */
    z-index: 1; /* Ensure text is above the image */
    width: 45%; /* Adjust width as needed */
  }
  
  .landing-page-hero-image img {
    height: auto;
    width: 100%;
    position: absolute; /* Position image absolutely */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    z-index: 0; /* Ensure image is behind the text */
  }
  
  .hero-heading {
    font-size: 60px;
    font-weight: 400;
    line-height: 52px;
    font-family: 'Montserrat';
    margin-bottom: 20px;
  }
  
  .hero-heading span {
    background: linear-gradient(180deg, #CF8A08 100%, #BF7901 100%, #FFEC53 100%, #DBAB1C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .hero-content {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    font-family: 'Poppins';
    margin-top: 0;
    width: 85%;
  }
  
  .hero-button {
    margin: 0;
    margin-top: 40px;
    padding: 10px 12px;
    height: 52px;
    width: 162px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Poppins';
    background: linear-gradient(180deg, #CF8A08 100%, #BF7901 100%, #FFEC53 100%, #DBAB1C 100%);
    color: white;
  }
  
  /* Responsive styles */
  @media (max-width: 426px) {
    .main-landing-page {
      padding: 0;
      flex-direction: column-reverse;
    }
  
    .landing-page-hero-text {
      width: 100%;
    }
  
    .hero-heading {
      margin: 0;
      font-size: 32px;
      padding: 20px;
    }
  
    .hero-content {
      display: none;
    }
  
    .hero-button {
      margin: 0;
      margin-left: 20px;
      padding: unset;
      height: 42px;
      width: 95px;
      font-size: 14px;
      font-weight: 600;
      font-family: 'Poppins';
      background: linear-gradient(180deg, #CF8A08 100%, #BF7901 100%, #FFEC53 100%, #DBAB1C 100%);
      color: white;
      border-radius: 10px;
      border: none;
    }
  
    .landing-page-hero-image img {
      display: none;
      max-width: 300px;
      transform: translateX(-30%);
    }
  }
  
  @media (min-width: 427px) and (max-width: 769px) {
    .hero-heading {
      font-size: 39px;
      margin-bottom: 10px;
    }
  
    .hero-content {
      font-size: 14px;
    }
  
    .hero-button {
      height: 41px;
      width: 114px;
      font-size: small;
      margin: 0;
      padding: 0;
      border-radius: 12px;
      border: none;
    }
  }
  
  