.qr-code-generator-container {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 2rem;
}

.generator-containers {
  width: 55%;
  border-image-source: linear-gradient(107.77deg, #FFD600 8.57%, rgba(0, 0, 0, 0.25) 23.6%, rgba(0, 0, 0, 0.25) 65.5%, #BF7901 94.73%);
  background: linear-gradient(99.99deg, rgba(241, 210, 64, 0.55) 10.36%, rgba(0, 0, 0) 94.72%);
  /* margin-top: 2rem; */
  /* border: 1px solid red; */

  overflow: auto;
  margin-bottom: 2rem;
  /* height: 50vh; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 20px; /* Add padding for inner spacing */
  background-color: white; /* Card background color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s; /* Smooth transition for hover effect */
}

.generator-containers:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.qr-code-generator-input {
  margin-bottom: 2rem;
  font-size: 16px;
  width: 85% !important; /* Make input take full width of card */
  padding: 10px; /* Add padding for better appearance */
  border: 1px solid rgb(243, 181, 98); /* Add border */
  border-radius: 5px; /* Rounded corners */
}







.qr-code-generator-button {
  width: 50%;
  /* margin-top: 2rem; */
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 1rem;
  background-color: rgb(243, 181, 98);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.qr-code-generator-button:hover {
  background-color: white;
  color: rgb(243, 181, 98);
}

.qr-code {
  margin-top: 20px;
}

.qr-code-image-container {
  width: 100%;
  background: linear-gradient(180deg, #CF8A08 -161.54%, #BF7901 0%, #FFEC53 92.31%, #DBAB1C 222.12%);
}
