@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.contact-us-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:  50px 70px;

}

.contact-us-content{
    display: block;
    width: 50%;
    align-content: center;
    padding: 20px;
}

.contact-section-head{
    font-size: 42px;
    font-weight: 500;
    font-family: 'Poppins';
    margin: 0;
    line-height: 52px;
}
.contact-section-text{
    font-size: 32px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
    padding-bottom: 20px;
}
.contact-section-text span{
    background: linear-gradient(180deg, #CF8A08 100%, #BF7901 100%,#FFEC53 100%, #DBAB1C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.contact-section-text-2{
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins';
    margin: 0;
    width: 85%;
    line-height: 25px;
}
.contact-us-form{
    width: 40%;
    border : 2px solid #CF8A08;
    border-radius: 15px;
    padding: 40px;
}
.form-label{
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins';
    margin: 0;
    margin-bottom: 10px;
    font-weight: normal !important;
    color: #FFFFFF !important;
}

.form-item .ant-form-item-label > label {
    color: #FFFFFF; /* Set label color to white */
}

.form-input{
    width: 100%;
    border-radius: 10px;
    padding: 15px 20px !important;
    /* margin-bottom: 10px; */
}

.submit-button{
    margin: 0;
    /* margin-top: 40px; */
    padding: 15px 12px;
    height: 52px;
    width: 162px;
    font-size: 16px;
    font-weight: 700;
    font-family: 'Poppins';
    background: linear-gradient(180deg, #CF8A08 100%, #BF7901 100%,#FFEC53 100%, #DBAB1C 100%);
    color: white;
    border-radius: 90px;
}

@media(max-width:426px){
    .contact-us-section{
        padding: 20px;
        padding-bottom: 50px;
        flex-direction: column;
    }
    .contact-us-content{
        width: 100%;
        padding: 0px 0px 50px 0px;
    }
    .contact-us-form{
        padding: 20px;
        width: 90%;
    }
    .contact-section-head{
        font-size: 20px;
        text-align: center;
    }
    .contact-section-text{
        font-size: 18px;
        text-align: center;
        padding-bottom: 10px;
    }
    .contact-section-text-2{
        font-size: 13px;
        text-align: center;
        font-weight: 300;
        width: 100%;
    }
    .form-input{
        padding: 10px !important;
    }

}

@media (min-width:427px) and (max-width:768px){
    .contact-us-section{
        padding: 20px;
        padding-bottom: 50px;
        flex-direction: row;
    }
    .contact-us-form{
        padding: 20px;
        width: 50%;
    }
    .form-input{
        padding: 10px !important;
    }
}