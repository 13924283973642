.navbar {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: black;
    padding: 10px 20px;
    font-family: 'Poppins', sans-serif;
    position: relative;
  }
  
  .logo img {
    width: 150px;
  }
  
  .nav-items {
    display: flex;
    align-items: center;
  }
  
  .nav-items-list {
    list-style: none;
    display: flex;
    align-items: center;
  }
  
  .nav-item {
    margin-left: 30px;
    font-size: 16px;
    color: white;
    font-weight: 700;
    cursor: pointer;
  }
  
  .signup-button {
    width: 110px;
    height: 42px;
    background: linear-gradient(180deg, #CF8A08 100%, #BF7901 100%, #FFEC53 100%, #DBAB1C 100%);
    color: white;
    border-radius: 15px;
    border: none;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    margin-left: 40px;
    width: 25%;
  text-align: center;
  }
  .nav-items-wrapper{
    display: flex;
    align-items: center;
  }
  .nav-toggle {
    display: none;
  }
  
  /* Responsive Styles */
  @media (max-width: 769px) {
    .logo {
      position: absolute;
      left: 02%;
      top: 40%;
    
    }
  
    .nav-signup {
      position: absolute;
      right: 20px;
      cursor: pointer;
    }
  
    .nav-toggle {
      display: block;
      cursor: pointer;
      position: absolute;
      left: 20px;
    }
    .nav-items {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: 45px;
      left: 47px;
      transition: 0.5s all ease-in-out;
      background: linear-gradient(180deg, #CF8A08 100%, #BF7901 100%, #FFEC53 100%, #DBAB1C 100%);
      z-index: 100;
      padding: 10px;
      width: 100%;
      max-width: 150px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
    .login{
      display: none;
    }
    .logo img {
      width: 100px;
    }
  
    .nav-items.active {
      display: flex;
      border-radius: 10px;
    }
  
    .nav-items-list {
      display: flex;
      flex-direction: column;
      padding: 0px 20px;
      margin: 0;
    }
  
    .nav-item {
      margin-left: 0;
      font-size: 12px;
      padding: 5px 0;
      width: 100%;
      text-align: left;
      font-weight: 500;
    }
  
    .signup-button {
      display: none; /* Hide button on mobile */
    }
  
    .nav-signup {
      padding: 10px;
      display: block; /* Show icon for Sign Up on mobile */
      background: none;
    }
  
    .nav-signup .signup-icon {
      font-size: 24px;
      color: rgb(208, 186, 17);
      display: block; /* Display icon only on mobile */
    }
  }
  