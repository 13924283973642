@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.about-us-section{
    background-color: black;
    font-family: 'Poppins';
    display: block;
    padding: 20px 0px 0px 0px;
}

.about-us-heading{
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    color: white;
    /* margin: auto 10px; */
}

.about-us-content{
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    /* margin-bottom: 45px; */
}
.about-us-content span{
    background: linear-gradient(180deg, #CF8A08 100%, #BF7901 100%,#FFEC53 100%, #DBAB1C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.about-image-section {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space between items */
    margin-top: 80px;
}

.about-us-image {
    flex: 1; /* Allow the image section to grow */
    margin-right: 20px; /* Add some space between text and image */
}

.about-us-image img{
    background-size: cover;
    height: auto;
    width: 100%;
    position: relative;
    
}
.about-us-image-content {
    flex: 1; /* Allow the text section to grow */
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 26px;
    width: 100%;
    text-align: center;
}
.about-us-image-content{
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 400;
    line-height: 26px;
}
@media (max-width:426px)
{
    .about-us-heading{
        font-size: 18px;
    }
    .about-us-content{
        font-size: 13px;
        padding: 0px 10px;
    }
    .about-image-section{
        flex-direction: column; /* Stack items vertically on small screens */
        align-items: flex-start;
    }
    .about-us-image {
        margin-right: 0; /* Remove right margin */
        margin-bottom: 20px; 
    }
    .about-us-image-content{
        font-size: 12px;
        position:unset;
        width: 100%;
        transform: unset;
        /* padding: 10px; */
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;

    }
    .about-us-image-content h3 p{
        border: 2px solid #BF7901;
        border-radius: 12px;
        padding: 10px;
        font-weight: 300;
        background: linear-gradient(45deg, #554a15 15%, #0a070740 65%, #000000 100%);
    }
}

@media (min-width:427px) and (max-width:769px){
    .about-us-image-content{
        font-size: 11px;
        top: 437px;
    }
    .about-us-image-content h3 p{
        border: 2px solid #BF7901;
        border-radius: 12px;
        padding: 10px;
        font-weight: 300;
        background: linear-gradient(45deg, #554a15 15%, #0a070740 65%, #000000 100%);
    }
}