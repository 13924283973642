.app-container {
  /* background-image: url('../image/login-signup-bg.jpg');
  background-size: cover;
  background-position: center;
  opacity: 1; */
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
}

.content-container {
  display: flex;
  width: 100%;
}

.main-content {
  flex-grow: 2;
  /* padding: 20px; */
  text-align: center;
  width: 100%;
}

.main-heading {
  margin-top: 5rem; 
  font-size: 24px;
  color: rgba(236, 205, 28, 0.904);
  margin-bottom: 20px;
}

/* Add styles for Header */
.header {
  /* background-color: rgb(243, 181, 98); */
  padding: 10px 20px;
}

.header-title {
  color: white;
  margin: 0;
}

/* Add styles for Sidebar */
.sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  height: 800px;
  text-align: left;
}

.sidebar-menu-item {
  margin-top: 3rem;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 10px;
}

.sidebar-menu-item:hover {
  /* border: 2px solid; */
  /* border-image-source: linear-gradient(107.77deg, #FFD600 8.57%, rgba(0, 0, 0, 0.25) 23.6%, rgba(0, 0, 0, 0.25) 65.5%, #BF7901 94.73%); */
  background: rgb(12,12,12);
  color: white;
  transition: all 0.5s ease-out;
  border-radius: 5px;
}

@media screen and (max-width: 1002px) {
  .sidebar {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: auto;
    padding: 20px 0px 5px 0px;
    box-shadow: 0px 0px 25px 0px linear-gradient(99.99deg, rgba(241, 210, 64, 0.55) 10.36%, rgba(0, 0, 0) 94.72%);
  }
  .content-container {
    flex-direction: column;
  }
  .sidebar-menu {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-around;
    align-items: center;
  }
  .sidebar-icons {
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }
  .sidebar-icon,
  .sidebar-menu-item {
    margin-top: 10px !important;
    margin-bottom: 0 !important;
    margin-left: 0.5rem;
  }
  .sidebar-content {
    display: flex;
  }
  .sidebar-toggle {
    width: 25%;
    margin: 0 auto;
  }
}
