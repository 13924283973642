.navbar {
  /* width: 100% !important; */
  min-height: 4rem;
  /* background-color: rgb(243, 181, 98); */
  border: 2px solid;
border-image-source: linear-gradient(107.77deg, #FFD600 8.57%, rgba(0, 0, 0, 0.25) 23.6%, rgba(0, 0, 0, 0.25) 65.5%, #BF7901 94.73%);
border-image-slice: 10%;
background: linear-gradient(99.99deg, rgba(241, 210, 64, 0.45) 10.36%, rgba(204, 204, 204, 0.03) 94.72%);

  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar button {
  width: 10%;
height: 52;
border-radius: 12px;
padding-top: 10px;
padding-right: 12px;
padding-bottom: 10px;
padding-left: 12px;
gap: 12px;
margin-right: 1rem;
color: white !important;

}

.navbar button:hover {
  color: #BF7901 !important;
  /* color: rgb(243, 181, 98); */
}

.logoDiv {
  display: flex;
  justify-content: left;
  align-self: center;
  width: 300px;
  padding: 5px;
  margin-left: 1rem;
  color: white;
  text-decoration: none;
  font-size: 17px;
}

.logo {
  width: 25%;
}

.button-text {
  display: inline;
  margin-left: 0.1rem;
}

/* Hide mobile icon and show desktop icon by default */
.mobile-icon {
  display: none;
}

.user-menu {
  /* margin-right: 2rem; */
  display: flex;
  align-items: center;
  cursor: pointer;
}

.username {
  color: #BF7901;
  margin-right: 8px; /* Space between username and icon */
}

.dummy-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%; /* Make it round */
  background-color: #ccc; /* Placeholder color */
}


.nav-items-list {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.nav-item {
  padding: 10px 15px; /* Add padding for better click area */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.nav-item:hover {
  background-color: rgba(243, 181, 98, 0.2); /* Light background on hover */
  color: #BF7901; /* Change text color on hover */
}

.navbar button.signup {
  background-color: #FFD600; /* Background color for Sign Up button */
  border: none; /* Remove default border */
  border-radius: 12px; /* Rounded corners */
  padding: 10px 15px; /* Padding for the button */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
  width: 25%;
  text-align: center;
}

.navbar button.signup:hover {
  background-color: #BF7901; /* Change background color on hover */
  color: white; /* Change text color on hover */
}



@media screen and (max-width: 1002px) {
  .navbar button {
    display: block;
  }

  /* Hide desktop icon and show mobile icon on small screens */
  .desktop-icon {
    display: none;
  }

  .mobile-icon {
    display: inline;
  }

  .navbar button .button-text {
    display: none;
  }
}
